html, body {
  height: 100%;
}
body {
    background-color: #000000;
    margin: 0;
    overflow: hidden;
    font-family: 'Roboto Mono', monospace;
}
body.hideCursor {
  cursor: none;
}
input {
  font-family: 'Roboto Mono', monospace;
}
a {
    color:#ffffaa;
}